import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GlowBackground from '../components/GlowBackground'
import { useApplicants } from '../contexts/ApplicantsContext'
import { useJobs } from '../contexts/JobsContext'
import { FaChevronDown, FaChevronUp, FaFilter } from 'react-icons/fa'
import { IoCheckbox, IoSquareOutline } from 'react-icons/io5'

function Applicants() {
  const navigate = useNavigate()
  const { jobs, fetchJobs } = useJobs()
  const { applicants, loading, error, fetchApplicants } = useApplicants()
  const fetchInitiated = useRef(false)
  const [sortField, setSortField] = useState('appliedAt')
  const [sortDirection, setSortDirection] = useState('desc')
  const [selectedPosition, setSelectedPosition] = useState('')
  const [showPositionFilter, setShowPositionFilter] = useState(false)
  const filterRef = useRef(null)
  const popupRef = useRef(null)
  const [popupHeight, setPopupHeight] = useState(0)

  const getJobTitle = (jobId) => {
    const job = jobs.find(j => j.id === jobId)
    return job ? job.title : 'Unknown Position'
  }

  const uniquePositions = [...new Set(applicants.map(a => getJobTitle(a.jobId)))]

  useEffect(() => {
    if (!fetchInitiated.current) {
      fetchInitiated.current = true
      fetchJobs()
      fetchApplicants()
    }
  }, [fetchJobs, fetchApplicants])

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowPositionFilter(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    if (showPositionFilter && popupRef.current) {
      const rect = popupRef.current.getBoundingClientRect()
      const totalHeight = rect.height + 62
      setPopupHeight(totalHeight)
    } else {
      setPopupHeight(0)
    }
  }, [showPositionFilter, uniquePositions])

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-AU', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  const handleRowClick = (id) => {
    navigate(`/applicants/${id}`)
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  const getSortedApplicants = () => {
    let filtered = selectedPosition 
      ? applicants.filter(a => getJobTitle(a.jobId) === selectedPosition)
      : applicants

    if (!sortField) return filtered

    return [...filtered].sort((a, b) => {
      let compareA, compareB
      
      if (sortField === 'name') {
        compareA = `${a.firstName} ${a.lastName}`
        compareB = `${b.firstName} ${b.lastName}`
      } else if (sortField === 'appliedAt') {
        compareA = new Date(a.appliedAt)
        compareB = new Date(b.appliedAt)
      }

      if (sortDirection === 'asc') {
        return compareA > compareB ? 1 : -1
      } else {
        return compareA < compareB ? 1 : -1
      }
    })
  }

  const getFilterPosition = () => {
    if (!filterRef.current) return 'bottom'
    
    const filterRect = filterRef.current.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    const spaceBelow = viewportHeight - filterRect.bottom
    
    // If space below is less than 300px, show above
    return spaceBelow < 300 ? 'top' : 'bottom'
  }

  return (
    <div className='flex flex-col items-center py-[20px] min-h-screen overflow-hidden'>
      <GlowBackground />
      <Navbar />
      
      <main className="container relative mb-[90px] md:mb-[120px]">
        <div className='relative bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mt-12'>
          <div className='p-7 sm:p-14'>
            <h1 className="h1-large text-center mb-10">Job Applications</h1>

            {loading ? (
              <div className="text-center text-white">Loading applications...</div>
            ) : error ? (
              <div className="text-center text-red-500">{error}</div>
            ) : applicants.length === 0 ? (
              <div className="text-center text-white">No applications received yet.</div>
            ) : (
              <div 
                className="overflow-x-auto"
                style={{ minHeight: popupHeight ? `${popupHeight}px` : 'auto' }}
              >
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-kaz-blue border-opacity-40">
                      <th 
                        className="text-left p-4 text-white cursor-pointer hover:text-blue-400 transition-colors"
                        onClick={() => handleSort('name')}
                      >
                        <div className="flex items-center gap-2">
                          <span>Name</span>
                          <span className={`transition-opacity ${sortField === 'name' ? 'opacity-100' : 'opacity-30'}`}>
                            {sortField === 'name' ? (
                              sortDirection === 'asc' ? <FaChevronUp /> : <FaChevronDown />
                            ) : <FaChevronDown />}
                          </span>
                        </div>
                      </th>
                      <th className="text-left p-4 text-white relative">
                        <div className="flex items-center gap-2">
                          <span>Position</span>
                          <button
                            onClick={() => setShowPositionFilter(!showPositionFilter)}
                            className={`p-1 rounded transition-colors ${
                              selectedPosition ? 'text-kaz-blue-light' : 'text-white opacity-50 hover:opacity-100'
                            }`}
                          >
                            <FaFilter size={14} />
                          </button>
                        </div>
                        
                        {showPositionFilter && (
                          <div 
                            ref={(el) => {
                              filterRef.current = el
                              popupRef.current = el
                            }}
                            className={`absolute z-10 ${
                              getFilterPosition() === 'top' 
                                ? 'bottom-full mb-2' 
                                : 'top-full mt-2'
                            } left-0 bg-[#000000] border border-kaz-blue-light/40 rounded-lg shadow-lg p-2 min-w-[200px] max-h-[300px] overflow-y-auto`}
                            style={{
                              scrollbarWidth: 'thin',
                              scrollbarColor: 'rgba(59, 130, 246, 0.5) transparent'
                            }}
                          >
                            <div 
                              className={`p-2 cursor-pointer rounded transition-colors flex items-center gap-2
                                hover:bg-kaz-blue-light/10 sticky top-0 bg-[#000000]`}
                              onClick={() => setSelectedPosition('')}
                            >
                              {!selectedPosition ? (
                                <IoCheckbox className="text-kaz-blue-light" size={20} />
                              ) : (
                                <IoSquareOutline className="text-gray-400" size={20} />
                              )}
                              <span className={!selectedPosition ? 'text-kaz-blue-light' : 'text-gray-300'}>
                                All Positions
                              </span>
                            </div>
                            {uniquePositions.map(position => (
                              <div
                                key={position}
                                className={`p-2 cursor-pointer rounded transition-colors flex items-center gap-2
                                  hover:bg-kaz-blue-light/10`}
                                onClick={() => setSelectedPosition(position)}
                              >
                                {selectedPosition === position ? (
                                  <IoCheckbox className="text-kaz-blue-light" size={20} />
                                ) : (
                                  <IoSquareOutline className="text-gray-400" size={20} />
                                )}
                                <span className={selectedPosition === position ? 'text-kaz-blue-light' : 'text-gray-300'}>
                                  {position}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </th>
                      <th className="text-left p-4 text-white">Email</th>
                      <th className="text-left p-4 text-white">Location</th>
                      <th 
                        className="text-left p-4 text-white cursor-pointer hover:text-blue-400 transition-colors"
                        onClick={() => handleSort('appliedAt')}
                      >
                        <div className="flex items-center gap-2">
                          <span>Applied</span>
                          <span className={`transition-opacity ${sortField === 'appliedAt' ? 'opacity-100' : 'opacity-30'}`}>
                            {sortField === 'appliedAt' ? (
                              sortDirection === 'asc' ? <FaChevronUp /> : <FaChevronDown />
                            ) : <FaChevronDown />}
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getSortedApplicants().map((applicant) => (
                      <tr 
                        key={applicant._id}
                        onClick={() => handleRowClick(applicant._id)}
                        className="border-b border-kaz-blue border-opacity-40 hover:bg-white/5 cursor-pointer transition-colors"
                      >
                        <td className="p-4 text-gray-300">
                          {applicant.firstName} {applicant.lastName}
                        </td>
                        <td className="p-4 text-gray-300">
                          {getJobTitle(applicant.jobId)}
                        </td>
                        <td className="p-4 text-gray-300">
                          {applicant.email}
                        </td>
                        <td className="p-4 text-gray-300">
                          {applicant.location}
                        </td>
                        <td className="p-4 text-gray-300">
                          {formatDate(applicant.appliedAt)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Applicants 