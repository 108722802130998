import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GlowBackground from '../components/GlowBackground'
import FormField from '../components/FormField'
import { useAuth } from '../contexts/AuthContext'

function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const { login } = useAuth()
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })
  const [formErrors, setFormErrors] = useState({
    username: '',
    password: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
    // Clear error when user starts typing
    setFormErrors(prev => ({
      ...prev,
      [e.target.name]: ''
    }))
    setError('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setError('')
    setFormErrors({})

    try {
      await login(formData)
      // Redirect to the page they tried to visit or to applicants by default
      const from = location.state?.from?.pathname || '/applicants'
      navigate(from, { replace: true })
    } catch (err) {
      console.error('Login error:', err)
      if (err.response?.data?.errors) {
        setFormErrors(err.response.data.errors)
      } else {
        setError(err.response?.data?.message || 'Failed to login. Please try again.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='flex flex-col items-center py-[20px] min-h-screen overflow-hidden'>
      <GlowBackground />
      <Navbar />
      
      <main className="container relative mb-[90px] md:mb-[120px]">
        <div className='relative bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mt-12'>
          <div className='p-7 sm:p-14'>
            <h1 className="h1-large text-center mb-10">Login</h1>

            {error && (
              <div className='w-full mb-8 bg-red-500/10 border-2 border-red-500/20 rounded-lg p-6 backdrop-blur-sm'>
                <p className='text-red-400'>{error}</p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-6">
              <FormField
                fieldName="username"
                fieldLabel="Username"
                value={formData.username}
                handleChange={handleChange}
                errors={formErrors.username}
                type="text"
              />

              <FormField
                fieldName="password"
                fieldLabel="Password"
                value={formData.password}
                handleChange={handleChange}
                errors={formErrors.password}
                type="password"
              />

              <button 
                type="submit"
                disabled={isLoading}
                className={`w-full btn btn-primary ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isLoading ? 'Logging in...' : 'Login'}
              </button>
            </form>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Login 