import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import GlowBackground from '../components/GlowBackground';
import ContactUsComponent from '../components/ContactUs';

function ContactUs() {
  return (
    <div className='flex flex-col items-center py-[20px] min-h-screen overflow-hidden'>
      <GlowBackground />
      <Navbar />
      
      <main className="container relative mb-[90px] md:mb-[120px]">
        <div className='relative bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mt-12'>
          <div className='p-7 sm:p-14'>
            <ContactUsComponent />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default ContactUs; 