import React, { useCallback, useEffect, useState } from 'react'
import { KIST_URL } from '../util/env_defaults';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

function ApplicationViewer({ application }) {
    const [fullApplication, setFullApplication] = useState(application);
    const [contentLoading, setContentLoading] = useState(false);
    const [resumeLoading, setResumeLoading] = useState(false);
    const { token } = useAuth();

    useEffect(() => {
        const fetchFullApplication = async () => {
            // get overall applicant data
            setContentLoading(true);
            const {data: applicantData} = await axios.get(`${KIST_URL}/jobs/applicants/${application._id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true
            });
            setFullApplication(applicantData);
            setContentLoading(false);

            // get resume data
            let resume = '';
            setResumeLoading(true);
            const resumeChunks = await Promise.all(
                Array.from({ length: application.expectedTotalChunks }, (_, i) =>
                    axios.get(`${KIST_URL}/jobs/applicants/${application._id}/resume/${i}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                        withCredentials: true
                    })
                )
            );
            resume = resumeChunks.map(response => response.data.resumeChunk).join('');
            applicantData.resume = resume;
            setFullApplication(applicantData);
            setResumeLoading(false);
        }
        if (application._id) {
            fetchFullApplication();
        }
    }, [application]);

    const renderPDF = useCallback(() => {
        if (!fullApplication.resume || resumeLoading) return (
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
            </div>
        )
        try {
            const base64Response = fullApplication.resume.split(',')[1];
            const binaryString = window.atob(base64Response);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);

            return (
                <iframe
                    src={blobUrl + '#toolbar=0'}
                    className="w-full h-[800px] rounded-lg bg-white"
                    title="Resume Preview"
                />
            )
        } catch {
            return <div className="text-red-500">Invalid resume format</div>
        }
    }, [fullApplication.resume, resumeLoading]);

    const renderDetails = useCallback(() => {
        if (contentLoading) return (
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
            </div>
        )
        return (
            <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h3 className="text-white text-lg mb-2">Personal Information</h3>
                        <div className="bg-gray-800/30 rounded-lg p-4 space-y-2">
                            <p className="text-gray-300">
                                <span className="text-white font-semibold">Name: </span>
                                {fullApplication.firstName} {fullApplication.lastName}
                            </p>
                            <p className="text-gray-300">
                                <span className="text-white font-semibold">Email: </span>
                                <a href={`mailto:${fullApplication.email}`} className="text-blue-400 hover:text-blue-300">
                                    {fullApplication.email}
                                </a>
                            </p>
                            <p className="text-gray-300">
                                <span className="text-white font-semibold">Phone: </span>
                                {fullApplication.phone || 'Not provided'}
                            </p>
                            <p className="text-gray-300">
                                <span className="text-white font-semibold">Location: </span>
                                {fullApplication.location}
                            </p>
                        </div>
                    </div>

                    <div>
                        <h3 className="text-white text-lg mb-2">Professional Profiles</h3>
                        <div className="bg-gray-800/30 rounded-lg p-4 space-y-2">
                            <p className="text-gray-300">
                                <span className="text-white font-semibold">LinkedIn: </span>
                                {fullApplication.linkedin || 'Not provided'}
                            </p>
                            <p className="text-gray-300">
                                <span className="text-white font-semibold">GitHub: </span>
                                {fullApplication.github || 'Not provided'}
                            </p>
                            <p className="text-gray-300">
                                <span className="text-white font-semibold">Portfolio: </span>
                                {fullApplication.portfolio || 'Not provided'}
                            </p>
                        </div>
                    </div>
                </div>

                <div>
                    <h3 className="text-white text-lg mb-2">Experience</h3>
                    <div className="bg-gray-800/30 rounded-lg p-4">
                        <p className="text-gray-300 whitespace-pre-line">{fullApplication.experience}</p>
                    </div>
                </div>

                <div>
                    <h3 className="text-white text-lg mb-2">Cover Letter</h3>
                    <div className="bg-gray-800/30 rounded-lg p-4">
                        <p className="text-gray-300 whitespace-pre-line">{fullApplication.coverLetter}</p>
                    </div>
                </div>
                {(fullApplication.appliedAt || fullApplication.salaryExpectation) && (
                    <div>
                        <h3 className="text-white text-lg mb-2">Application Details</h3>
                        {fullApplication.appliedAt && (
                            <div className="bg-gray-800/30 rounded-lg p-4 space-y-2">
                                <p className="text-gray-300">
                                    <span className="text-white font-semibold">Submitted: </span>
                                    {new Date(fullApplication.appliedAt).toLocaleString('en-AU', { 
                                        day: 'numeric',
                                        month: 'long', 
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                    })}
                                </p>
                            </div>
                        )}
                        {fullApplication.salaryExpectation && (
                            <div className="bg-gray-800/30 rounded-lg p-4 space-y-2">
                                <p className="text-gray-300">
                                    <span className="text-white font-semibold">Salary Expectation: </span>
                                    {fullApplication.salaryExpectation}
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }, [fullApplication, contentLoading]);

    return (
        <div className="bg-gray-800/30 rounded-lg p-6 backdrop-blur-sm space-y-6">
            {renderDetails()}
            
            <div>
                <h3 className="text-white text-lg mb-2">Resume</h3>
                {renderPDF()}
            </div>
        </div>
    )
}

export default ApplicationViewer 