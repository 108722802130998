import React, { useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GlowBackground from '../components/GlowBackground'
import ApplicationViewer from '../components/ApplicationViewer'
import { useApplicants } from '../contexts/ApplicantsContext'
import { useJobs } from '../contexts/JobsContext'

function ApplicantDetails() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { fetchJobs } = useJobs()
  const { applicants, loading, error, fetchApplicants } = useApplicants()
  const fetchInitiated = useRef(false)

  useEffect(() => {
    if (!fetchInitiated.current) {
      fetchInitiated.current = true
      fetchJobs()
      fetchApplicants()
    }
  }, [fetchJobs, fetchApplicants])

  const applicant = applicants.find(a => a._id === id)

  return (
    <div className='flex flex-col items-center py-[20px] min-h-screen overflow-hidden'>
      <GlowBackground />
      <Navbar />
      
      <main className="container relative mb-[90px] md:mb-[120px]">
        <div className='relative bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mt-12'>
          <button 
            onClick={() => navigate('/applicants')}
            className="flex items-center gap-2 text-kaz-blue hover:text-white transition-colors duration-300 m-7 mb-0 px-4 py-2 rounded-full border-2 border-kaz-blue border-opacity-40 hover:border-opacity-100 hover:bg-kaz-blue hover:bg-opacity-25"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Back to Applications
          </button>

          <div className='p-7 sm:p-14'>
            {loading ? (
              <div className="text-center text-white">Loading application...</div>
            ) : error ? (
              <div className="text-center text-red-500">{error}</div>
            ) : !applicant ? (
              <div className="text-center text-white">Application not found.</div>
            ) : (
              <ApplicationViewer application={applicant} />
            )}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default ApplicantDetails 