import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './routes/Home'
import Jobs from './routes/Jobs'
import JobApplication from './routes/JobApplication'
import Login from './routes/Login'
import Applicants from './routes/Applicants'
import ApplicantDetails from './routes/ApplicantDetails'
import ContactUs from './routes/ContactUs'
import NotFound from './routes/NotFound'
import ProtectedRoute from './components/ProtectedRoute'
import { JobsProvider } from './contexts/JobsContext'
import { AuthProvider } from './contexts/AuthContext'
import { ApplicantsProvider } from './contexts/ApplicantsContext'
import Logout from './routes/Logout'
import './index.css'

function App() {
    return (
        <AuthProvider>
            <JobsProvider>
                <ApplicantsProvider>
                    <Router>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/jobs" element={<Jobs />} />
                            <Route path="/jobs/:id" element={<JobApplication />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route 
                                path="/applicants" 
                                element={
                                    <ProtectedRoute>
                                        <Applicants />
                                    </ProtectedRoute>
                                } 
                            />
                            <Route 
                                path="/applicants/:id" 
                                element={
                                    <ProtectedRoute>
                                        <ApplicantDetails />
                                    </ProtectedRoute>
                                } 
                            />
                            <Route path="/contact" element={<ContactUs />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Router>
                </ApplicantsProvider>
            </JobsProvider>
        </AuthProvider>
    )
}

export default App
