import React, { useEffect } from 'react'
import GlowBackground from '../components/GlowBackground'
import ScrollToTopButton from '../components/ScrollToTopButton'
import Navbar from '../components/Navbar'
import Welcome from '../components/Welcome'
import Partners from '../components/Partners'
import ServicesTitle from '../components/ServicesTitle'
import ServicesSummary from '../components/ServicesSummary'
import FinancialServices from '../components/FinancialServices'
import MarketingServices from '../components/MarketingServices'
import Toolkit from '../components/Toolkit'
import Products from '../components/Products'
import About from '../components/About'
import Articles from '../components/Articles'
import Team from '../components/Team'
import FinalCallToAction from '../components/FinalCallToAction'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Faq from '../components/Faq'

import ParticlesBackground from '../components/Particles'
import SmoothScroll from 'smooth-scroll'

function Home() {
  useEffect(() => {
    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 1000,
      speedAsDuration: true,
      easing: 'easeInOutCubic',
    })

    const stopSmoothScrollOnWheel = () => {
      scroll.cancelScroll()
    }

    window.addEventListener('wheel', stopSmoothScrollOnWheel)
  }, [])

  return (
    <div className='flex flex-col items-center py-[20px] overflow-hidden'>
      <ParticlesBackground />
      <GlowBackground />
      <ScrollToTopButton />
      <Navbar />
      <Welcome />
      <Partners />
      <ServicesTitle />
      <ServicesSummary />
      <FinancialServices />
      <MarketingServices />
      <Toolkit />
      <Products />
      <Faq />
      <About />
      <Team />
      <Articles />
      <FinalCallToAction />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Home
